<template>
  <section>
    <a-breadcrumb>
      <a-breadcrumb-item>
        <router-link :to="{ name: 'types' }">Типы объектов</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{ pageTitle }}</a-breadcrumb-item>
    </a-breadcrumb>
    <a-page-header :title="pageTitle"
                   @back="() => $router.push({ name: 'types' })"
                   :class="{ loading }">
      <template #extra>
        <a-button key="delete" size="large" @click="handleDelete">
          <template #icon>
            <delete-outlined/>
          </template>
        </a-button>
      </template>
    </a-page-header>

    <main v-if="loading">
      <a-skeleton/>
    </main>
    <main v-else>
      <a-tabs v-model:active-key="currentTab">
        <!--  Основное  -->
        <a-tab-pane key="info" tab="Основное">
          <a-form class="offset-lg" :label-col="{ sm: 12, md: 6 }" :wrapper-col="{ sm: 12, md: 12 }"
                  :model="formInfo" ref="refFormInfo">
            <a-form-item label="Название типа" name="name" :rules="[
              { required: true, message: 'Пожалуйста, введите название', trigger: 'blur' },
              { min: 1, max: 255, message: 'Длина должна быть от 1 до 255 символов', trigger: 'change' }
            ]">
              <a-input v-model:value="formInfo.name" placeholder="Введите название"/>
            </a-form-item>
            <a-form-item label="Доступен для бронирования">
              <a-switch v-model:checked="formInfo.reservationAvailable"/>
            </a-form-item>
            <a-form-item label="Добавление участников">
              <a-switch v-model:checked="formInfo.userInvitationAvailable"/>
            </a-form-item>
            <a-form-item label="Политика доступов" name="reservationPolicyId" :rules="[
              { required: true, message: 'Пожалуйста, выберите вариант', trigger: 'blur' },
            ]">
              <a-select v-model:value="formInfo.reservationPolicyId" show-search
                        :default-active-first-option="false"
                        :show-arrow="true"
                        :filter-option="false"
                        :not-found-content="null"
                        @search="getPolitics"
                        placeholder="Выберите политику" size="large">
                <a-select-option :value="option.id" v-for="(option) in politicsList" :key="option.id">
                  {{ option.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="Превью" name="preview">
              <app-input-file v-model="formInfo.fileList" placeholder="Выберите изображение"/>
            </a-form-item>
            <a-form-item label="Цвет идентификатора">
              <color-switch size="large" v-model="formInfo.idColor"
                            :color-list="[
                                'FFFFFF', 'FFDA69', 'E79B0A', 'E97A14', 'FE7275', 'E24040',
                                'A80104', '6FCF97', '27AE3D', '199C8C', '81D8FF', '35ADE3',
                                '2F80ED', '105085', '8427E1', 'BB6BD9', 'E1A3F8', '472FD7',
                                '8677DE', '2E3137', '760000', '744D01', 'B56C02', 'DFB970',
                            ]"/>
            </a-form-item>
            <div class="form-footer">
              <a-button size="large" @click="handleCancel">Отменить</a-button>
              <a-button type="primary" size="large" @click="handleSubmit('info')" :loading="loadingProgress"
                        :disabled="!isFormChanged('info')">
                Сохранить
              </a-button>
            </div>
          </a-form>
        </a-tab-pane>
        <!--  Аттрибуты  -->
        <a-tab-pane key="attributes" tab="Атрибуты" :force-render="true">
          <attributes-editor v-model="formAttributes.attributes" @load="fixChanges"/>
          <div class="form-footer">
            <a-button size="large" @click="handleCancel">Отменить</a-button>
            <a-button type="primary" size="large" @click="handleSubmit('attributes')" :loading="loadingProgress"
                      :disabled="!isFormChanged('attributes')">
              Сохранить
            </a-button>
          </div>
        </a-tab-pane>
      </a-tabs>
    </main>

    <object-type-delete-busy-modal
        v-model:visible="isDeletingBusyType" :object-type="deletingBusyType"
        @proceed="handleDeleteAndRewrite"/>

    <response-question-modal
        v-model:visible="isResponseQuestion" :message="responseQuestionMessage" :admin-message="responseQuestionAdminMessage"
        @proceed="handleResponseQuestionProceed"/>

  </section>
</template>

<script>
import { ref, unref } from "@vue/reactivity";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { onMounted, watch } from "@vue/runtime-core";
import { fileHelper, urlHelper } from "@/compositions/commonFunctions";
import {
  DeleteOutlined
} from '@ant-design/icons-vue';
import ColorSwitch from "@/components/admin/ColorSwitch";
import { Modal, notification } from "ant-design-vue";
import { requestAPI, getOneData } from "@/compositions/objectTypes";
import { FETCH_RESERVATION_POLICIES } from "@/store/politics";
import AttributesEditor from "@/components/admin/AttributesEditor";
import AppInputFile from "@/components/admin/shared/ul/AppInputFile";
import { resourcesData } from "@/compositions/resources";
import { validatorFunctions } from "@/compositions/validators";
import ObjectTypeDeleteBusyModal from "../../../components/admin/objectTypes/ObjectTypeDeleteBusyModal";
import { keepUnsavedChangesManager } from "@/compositions/keepUnsavedChangesManager";
import ResponseQuestionModal from "@/components/admin/politics/ResponseQuestionModal";

export default {
  name: "TypeEditPage",
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const loading = ref(true);
    const loadingProgress = ref(false);
    const pageTitle = ref('Загрузка...');
    const refFormInfo = ref(null);
    const politicsList = ref([]);
    const currentTab = ref('info');

    const isDeletingBusyType = ref(false);
    const deletingBusyType = ref(null);
    const isResponseQuestion = ref(false);
    const responseQuestionMessage = ref('');
    const responseQuestionAdminMessage = ref('');
    const responseQuestionPayload = ref({});

    const { getType, updateType, removeType } = requestAPI();
    const { formInfo, formAttributes } = getOneData();
    const { uploadFile } = resourcesData();
    const { attributesValidator } = validatorFunctions();
    const { useKeepManager, fixChanges, isFormChanged } = keepUnsavedChangesManager();

    onMounted(async () => {
      if (route.params?.id) {
        loading.value = true;
        const res = await getType(route.params?.id)
        if (res?.id) {
          // Fill form
          formInfo.value = { ...res };
          formInfo.value.fileList = [];
          formInfo.value.idColor = res.idColor ? res.idColor : 'FFFFFF';
          formInfo.value.reservationPolicyId = res.reservationPolicy?.id;

          formAttributes.value.attributes = res.attributes;
          formInfo.value.attributes = undefined;

          if (res.previewUrl) {
            formInfo.value.fileList.push(await fileHelper.createFileFromUrl(res.previewUrl, 'Файл превью'));
          }
          await getPolitics();
          if (res.reservationPolicy && !politicsList.value.find((option) => option.id === res.reservationPolicy?.id)) politicsList.value.unshift(res.reservationPolicy);

          //
          pageTitle.value = res.name;
          urlHelper.setPageTitle(pageTitle.value);
          useKeepManager({ 'info': formInfo, 'attributes': formAttributes }, handleKeepChanges);

          // Открыть нужный таб
          if (route.hash) {
            const hash = route.hash.substr(1);
            if (['info', 'attributes'].indexOf(hash) >= 0) currentTab.value = hash;
          }

        } else {
          return notification.error({
            message: '404',
            description: 'Тип объекта не найден',
          });
        }
      } else {
        notification.error({
          message: '404',
          description: 'Тип объекта не найден',
        });
        router.push({ name: 'types' });
      }
      loading.value = false;
    });

    // Поиск политик
    async function getPolitics(search) {
      const res = await store.dispatch(FETCH_RESERVATION_POLICIES, { search });
      politicsList.value = res?.data || [];
    }

    async function handleSubmit(formName) {
      let requestForm = {};

      // Валидируем и сохраняем основную информацию
      if (formName === 'info') {
        await refFormInfo.value
            .validate()
            .then(async () => {
              loadingProgress.value = true;
              requestForm = unref(formInfo);
              requestForm.attributes = undefined;
              // Конвертировать файлы в base64
              requestForm.previewImage = null;
              if (requestForm.fileList.length > 0) {
                const file = requestForm.fileList[0];
                if (file?.url) {
                  requestForm.previewImage = file?.url;
                } else {
                  const previewImageBase64 = await fileHelper.createBase64FromFile(file);
                  const previewImage = await uploadFile(previewImageBase64, route.name);
                  requestForm.previewImage = previewImage?.url;
                }
              }
            })
            .finally(() => {
              loadingProgress.value = false;
            });
      }

      // Сохраняем атрибуты
      if (formName === 'attributes') {
        requestForm = unref(formAttributes);
        // Проверка заполнены ли атрибуты корректно
        if (!await attributesValidator(requestForm.attributes)) {
          return notification.error({
            message: 'Проверьте атрибуты',
            description: 'В таблице есть незаполненные поля.',
          });
        }
      }

      // Отправляем выбранную проверенную форму
      try {
        loadingProgress.value = true;
        const res = await updateType(requestForm, route.params?.id);
        if (res?.id) {
          await handleSubmitResponse(res, formName);
        } else {
          // Предлагаем удалить все бронирования, которые не подходят под изменения
          responseQuestionMessage.value = res?.message;
          responseQuestionAdminMessage.value = res?.messageAdmin;
          isResponseQuestion.value = true;
          responseQuestionPayload.value = { formName, requestForm };
        }
      } catch (error) {
        console.error('Ошибка отправки данных');
      } finally {
        loadingProgress.value = false;
      }
    }

    async function handleResponseQuestionProceed(params) {
      const requestForm = unref(responseQuestionPayload)?.requestForm;
      const res = await updateType(requestForm, route.params?.id, params);
      await handleSubmitResponse(res, unref(responseQuestionPayload)?.formName);
    }

    async function handleSubmitResponse(res, formName) {
      if (res?.id) {
        notification.success({
          message: 'Успех',
          description: 'Изменения сохранены',
        });
        // Новый заголовок
        pageTitle.value = res.name;
        urlHelper.setPageTitle(pageTitle.value);
        fixChanges(formName);
        isResponseQuestion.value = false;
      } else {
        notification.error({ message: 'Ошибка сохранения', description: 'Попробуйте позже' });
      }
    }

    async function handleDelete() {
      Modal.confirm({
        title: 'Удалить тип объекта?',
        okText: 'Удалить',
        okType: 'danger',
        cancelText: 'Отмена',
        width: 400,
        async onOk() {
          const res = await removeType(route.params?.id);
          if (res?.id) {
            fixChanges();
            notification.success({
              message: 'Успех',
              description: 'Успешно удалено',
            });
            router.push({ name: 'types' });
          } else {
            deletingBusyType.value = unref(formInfo);
            isDeletingBusyType.value = true;
          }
        },
      });
    }

    async function handleDeleteAndRewrite(replaceObjectTypeId) {
      isDeletingBusyType.value = false;
      try {
        const res = await removeType(unref(deletingBusyType)?.id, { replaceObjectTypeId });
        if (res?.id) {
          notification.success({
            message: 'Успех',
            description: 'Успешно удалено',
          });
          router.push({ name: 'types' });
        } else {
          notification.error({
            message: 'Ошибка удаления',
            description: 'Удаление не удалось',
          });
        }
      } catch (e) {
        console.error(e);
      }
    }

    function handleKeepChanges(changedForms) {
      if (changedForms.length > 0) currentTab.value = changedForms[0];
    }

    function handleCancel() {
      router.push({ name: 'types' });
    }

    watch(currentTab, (tab) => {
      window.location.hash = tab;
    });

    return {
      pageTitle, loading, loadingProgress,
      currentTab, fixChanges, isFormChanged,
      formInfo, refFormInfo, formAttributes,
      politicsList, getPolitics, isDeletingBusyType, deletingBusyType,
      handleSubmit, handleCancel, handleDelete, handleDeleteAndRewrite,
      isResponseQuestion, responseQuestionMessage, responseQuestionAdminMessage, handleResponseQuestionProceed,
    }
  },
  components: {
    ResponseQuestionModal,
    ObjectTypeDeleteBusyModal,
    AppInputFile,
    AttributesEditor,
    ColorSwitch,
    DeleteOutlined
  }
}
</script>
