<template>
  <div class="color-switch">
    <color-view :size="size" :color="color" :selected="color === currentColor"
                v-for="color in colorList" :key="color"
                @click="selectColor(color)"
    />

    <color-view :size="size" :color="nonUsualColor" :selected="nonUsualColor === currentColor"
                @click="selectColor(nonUsualColor)" v-if="nonUsualColor"
    />

  </div>
</template>

<script>
import ColorView from "@/components/admin/ColorView";
import { computed, ref } from "@vue/reactivity";
import { nextTick } from "@vue/runtime-core";
import { onMounted } from "@vue/runtime-core";

export default {
  name: 'ColorSwitch',
  props: {
    modelValue: String,
    colorList: Array,
    size: { type: String, default: 'medium' }, // small | medium | large
  },
  setup(props, { emit }) {

    const nonUsualColor = ref('');

    const currentColor = computed({
      get: () => props.modelValue,
      set: async (newValue) => {
        nextTick(() => emit('update:modelValue', newValue));
      }
    });

    onMounted(() => {
      // Если выбранный цвет не содержится в списке доступных, добавь этот цвет в доступные
      if (currentColor.value && props.colorList.indexOf(currentColor.value) === -1) {
        nonUsualColor.value = currentColor.value;
      }
    });

    function selectColor(color) {
      currentColor.value = color;
    }

    return { currentColor, selectColor, nonUsualColor }
  },
  components: { ColorView },
}
</script>

<style lang="less" scoped>
.color-switch {
  display: inline-block;
  background-color: @background-primary;
  border-radius: @border-radius-base;
  padding: 0.25rem;
  font-size: 0;
  line-height: normal;
  vertical-align: top;
  max-width: 490px;

  &:deep(.color-border) {
    padding: 0;
    margin: 0.25rem;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
